import React from "react"
import Image from "../image"

const ProfileCards = ({ imageSrc, name, title, company }) => {
  console.log({ imageSrc })
  return (
    <div style={{ width: "210px", padding: "10px" }}>
      {/* <img
        // src="https://media-exp1.licdn.com/dms/image/C5603AQGXRU8H3yjOow/profile-displayphoto-shrink_800_800/0/1597499623289?e=1618444800&v=beta&t=VXZbWMdoCfW-_4G_-FBMfLKDqgeoRXbPRq_uLP0x3hs"
        src={imageSrc}
        alt="card"
        style={{
          width: "90%",
          borderRadius: "5px",
          //  border: "solid 3px #fec600",
          filter: "grayscale(100%)",
        }}
      /> */}
      <div style={{ borderLeft: "7px solid #fec600" }}>
        <div style={{ filter: "grayscale(100%)" }}>
          <Image
            src={imageSrc}
            style={{
              width: "90%",
              borderRadius: "5px",
              //  border: "solid 3px #fec600",
              filter: "grayscale(100%)",
            }}
          ></Image>
        </div>
      </div>
      <div
        style={{
          // borderLeft: "4px solid #fec600",
          marginTop: "7px",
          height: "120px",
        }}
      >
        <p
          style={{
            textAlign: "left",
            fontSize: "16px",
            fontWeight: "500",
            textTransform: "uppercase",
            paddingLeft: "7px",
          }}
        >
          {name}
        </p>
        <p
          style={{
            textAlign: "left",
            fontSize: "14px",
            fontWeight: "400",
            // textTransform: "uppercase",
            paddingLeft: "7px",
            paddingLeft: "7px",
            marginTop: "7px",
            color: "rgba(51, 51, 51, 0.6)",
          }}
        >
          {/* Head of Partnership Marketing and Promotions */}
          {title}
        </p>
        <p
          style={{
            textAlign: "left",
            fontSize: "14px",
            fontWeight: "700",
            // textTransform: "uppercase",
            paddingLeft: "7px",
            paddingLeft: "7px",
            // marginTop: "7px",
            color: "rgba(51, 51, 51, 0.8)",
          }}
        >
          {/* Head of Partnership Marketing and Promotions */}
          {company}
        </p>
        {/* <p
          style={{
            textAlign: "left",
            fontSize: "16px",
            fontWeight: "400",
            paddingLeft: "7px",
          }}
        >
          {company}
        </p> */}
      </div>
    </div>
  )
}

export default ProfileCards
