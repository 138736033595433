import React from "react"
import logo from "./../../images/logo.png"
import { navigate } from "gatsby"
import govoyayge from "./../../images/go-voyayge.png"
import coo from "./../../images/coo.png"
import logoIcon from "./../../images/logoIcon.png"
// import gosee from "./../../images/voyayge banner.png"
import gosee from "./../../images/gosee.png"
import herofun from "./../../images/logofun.png"
import climate from "./../../images/climate.png"
import tree from "./../../images/tree.png"
import vjprofile from "./../../images/profile_vj.png"
import success from "./../../images/success.svg"
import wiggle from "./../../images/wiggle.svg"
import map from "./../../images/loving-map.png"
import { useEffect } from "react"
import { analytics } from "./../../components/firebase/firebase"
import ProfileCards from "../ProfileCards/ProfileCards"
import Image from "../image"
import { LazyLoadImage } from "react-lazy-load-image-component"
const About = () => {
  useEffect(() => {
    analytics.logEvent("screen_view", { screen_name: "about" })
  }, [])

  useEffect(() => {
    document.title = "About | voyayge"
  }, [])

  const customNavigateTo = url => {
    window.open(url, "_blank")
  }
  return (
    <div>
      {/* <section className="hero is-small" style={{backgroundColor:'#fec600'}}
        >
  <div className="hero-body">
    <div className="container">
      <p className="title">
        OUR MISSION
      </p>
      <p className="subtitle">
      Inspire and help travelers explore the world by building the best
              platform that connects people to craft bespoke travel experiences.
      </p>
    </div>
  </div>
</section> */}
      <div className="columns is-multiline is-vcentered is-tablet">
        <div className="column is-2-desktop is-1-tablet"></div>
        <div className="column is-8-desktop is-10-tablet">
          <p
            style={{
              fontSize: "2.5rem",
              fontWeight: "700",
              borderBottom: "2px solid #fec600",
              marginTop: "15px",
            }}
          >
            About
          </p>
          <div style={{ margin: "0px auto", marginTop: "50px", maxWidth: "" }}>
            {/* <img src={map} alt="card" style={{ width: "100%" }} /> */}
            {/* <img
              src={gosee}
              alt="voyayge logo icon"
              style={{ width: "100%" }}
            /> */}
            {/* <Image src="gosee.png"></Image> */}
            <Image src="map.png"></Image>
            {/* <img src={herofun} alt="" /> */}
            {/* <Image src="voyayge-world.png"></Image> */}
          </div>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div
          className="column is-8-desktop is-10-tablet"
          style={
            {
              // backgroundImage :`url(${map})`
            }
          }
        >
          <div>
            {/* <p
              style={{
                fontSize: "24px",
                fontWeight: "400",
                textAlign: "center",       marginTop:'20px',
                color: "#fec600",
              }}
            >
              OUR MISSION
            </p> */}
            <p
              className=""
              style={{
                fontSize: "42px",
                fontWeight: "800",
                textAlign: "center",
                //marginTop:'20px',
                color: "#fec600",
                lineHeight: "1.0",
              }}
            >
              <span
                style={{
                  textTransform: "uppercase",
                  color: "rgba(74,74,74,0.8)",
                }}
              >
                raison d
              </span>
              <span
                style={{
                  color: "#fec600",
                }}
              >
                '
              </span>
              <span
                style={{
                  textTransform: "uppercase",
                  color: "rgba(74,74,74,0.8)",
                }}
              >
                etre
              </span>
              <span
                style={{
                  color: "#fec600",
                }}
              >
                .
              </span>
              {/* <span
                style={{
                      color: "rgba(74,74,74,0.8)",
                }}
              >
                WE ARE<br></br>ON A MISSION
              </span>
              <span
                style={{
                  color: "#fec600",
                }}
              >
                .
              </span> */}
            </p>
            {/* <div style={{ maxWidth: "250px", margin: "0 auto" }}>
              <img src={mission} alt="card" style={{ width: "100%" }} />
            </div> */}
            <p
              style={{
                marginTop: "14px",
                fontSize: "16px",
                fontWeight: "400",
                textAlign: "center",
                lineHeight: "1.7",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
              }}
            >
              {/* To Inspire and help travelers explore the world by building the
              best platform that connects people to craft bespoke travel
              experiences. */}
              {/* We are in pursuit of building the best travel platform that
              inspires and helps travelers explore the world using craft bespoke
              travel experiences. */}
              {/* WE ARE IN PURSUIT OF BUILDING THE BEST TRAVEL PLATFORM THAT
              EMPOWERS TRAVELERS EXPLORE THE WORLD. */}
              {/* WE ARE IN PURSUIT TO EMPOWER TRAVELERS EXPLORE THE WORLD. */}
              IN PURSUIT TO EMPOWER TRAVELERS TO EXPLORE THE WORLD
            </p>
          </div>
        </div>
        {/* <div className="column is-4-desktop is-4-tablet">
          <div style={{ maxWidth: "600px", margin: "0 auto" }}>
            <img src={govoyayge} alt="card" style={{ width: "100%" }} />
          </div>
        </div> */}
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-4-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-4-desktop is-10-tablet">
          {" "}
          <hr className="hr-branded"></hr>
        </div>
        <div className="column is-4-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-10-tablet">
          {" "}
          <p
            className=""
            style={{
              fontSize: "42px",
              fontWeight: "800",
              //textAlign: "center",
              // marginBottom: "20px",
              color: "#fec600",
              lineHeight: "1.0",
            }}
          >
            <span
              style={{
                color: "rgba(74,74,74,0.8)",
              }}
            >
              OUR<br></br>STORY
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
              {/* <i className="fas fa-heart" style={{ fontSize: "14px" }}></i> */}
            </span>
          </p>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-4-desktop is-6-tablet">
          <div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "400",
                textAlign: "Left",
              }}
            >
              <span>
                {/* We built voyayge to democratize custom-crafted travel planning.
                Today, a typical traveler has to navigate multiple knowledge
                silos and use various platforms to get inspired, plan, and share
                their next memorable vacation. This research takes time and
                effort and we understand that you would rather spend this time
                on more important things that bring joy to life! <br />
                <br /> We at voyay!ge are committed to building a travel
                platform to streamline this process on your next travel. */}
                {/* We take <>your privacy</> seriously. Your details
                and travel history will be not shared with anyone. */}
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    color: "rgba(14, 19, 24, 0.60)",
                    // textAlign: "justify",
                    lineHeight: "1.8",
                    // fontStyle: "italic",
                  }}
                >
                  As a fellow traveler and a parent myself, I have spent
                  countless hours researching in order to plan my vacations.
                  From finding the right mix of restaurants for different
                  cuisines and diets to seeking out various activities and
                  attractions to make it enjoyable for everyone on the trip, I
                  have done it all. I have also been fortunate to travel with my
                  larger extended family which entails a lot more research to
                  ensure the travel plan is right. <br></br>
                  <br></br>Today, like most of you, my research is
                  time-consuming and tiring to gather information from multiple
                  travel sites, blogs, social media, user forums, etc. Therefore
                  a traveler has to dig for information and iterate through to
                  make a plan suitable for their specific needs. Although there
                  are bespoke travel solutions available today, they are very
                  expensive.
                </p>
                {/* ❤️ */}
              </span>
            </p>
          </div>

          <div style={{ maxWidth: "", margin: "0 auto" }}>
            {/* <Image
              src="gosee.png"
              style={{
                width: "90%",

              }}
            ></Image> */}
            {/* <Image
              src="profile_vj.png"
              style={{
                width: "90%",
                borderRadius: "50%",
                border: "solid 3px #fec600",
                filter: "grayscale(90%)",
              }}
            ></Image> */}
          </div>
        </div>
        <div className="column is-4-desktop is-4-tablet is-hidden-mobile">
          <div style={{ maxWidth: "300px", margin: "0 auto" }}>
            {/* <Image
              src="profile_vj.png"
              style={{
                width: "90%",
                borderRadius: "50%",
                border: "solid 3px #fec600",
                filter: "grayscale(90%)",
              }}
            ></Image> */}
            <img
              src={vjprofile}
              alt="card"
              style={{
                width: "100%",
                padding: "10px",
                // borderRadius: "50%",
                // border: "solid 2px #fec600",
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              VIJAY KOMPELLA
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              FOUNDER & CEO
            </p>
            <div
              style={{
                margin: "0 auto",
                maxWidth: "175px",
                textAlign: "center",
                display: "none",
              }}
            >
              <i
                className="fab fa-2x fa-linkedin"
                style={{
                  color: "#fec600",
                  // padding: "10px",
                  margin: "0 auto",
                  marginTop: "12px",
                  cursor: "pointer",
                  width: "100%",
                  //  textAlign:'center'
                }}
                onClick={() => {
                  customNavigateTo(
                    "https://www.linkedin.com/in/vijay-kompella-44953a3/"
                  )
                }}
              >
                {" "}
                <span
                  style={{
                    color: "#fec600",
                    // padding: "10px",
                    marginTop: "10px",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "400",
                    textAlign: "center",
                    letterSpacing: "1px",
                  }}
                >
                  /VKOMPELLA
                </span>
              </i>
            </div>
          </div>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        {/* <div className="column is-5-desktop is-1-tablet"></div>
        <div className="column is-2-desktop is-10-tablet">
          <img src={logo} alt="logo" />
        </div>
        <div className="column is-5-desktop is-1-tablet"></div> */}
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-4-desktop is-10-tablet is-hidden-mobile">
          {" "}
          <div style={{ maxWidth: "600px", margin: "0 auto" }}>
            <Image
              src="gosee.png"
              style={{
                width: "10%",
              }}
            ></Image>
            {/* <img
              src={coo}
              style={{
                width: "90%",
                filter: "grayscale(90%)",
              }}
            ></img>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              TAMMY TAW
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              COO
            </p> */}
          </div>
        </div>
        <div className="column is-hidden-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-hidden-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-4-desktop is-10-tablet">
          <span>
            {/* We built voyayge to democratize custom-crafted travel planning.
                Today, a typical traveler has to navigate multiple knowledge
                silos and use various platforms to get inspired, plan, and share
                their next memorable vacation. This research takes time and
                effort and we understand that you would rather spend this time
                on more important things that bring joy to life! <br />
                <br /> We at voyay!ge are committed to building a travel
                platform to streamline this process on your next travel. */}
            {/* We take <>your privacy</> seriously. Your details
                and travel history will be not shared with anyone. */}
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                color: "rgba(14, 19, 24, 0.60)",
                // textAlign: "justify",
                lineHeight: "1.8",
                // fontStyle: "italic",
              }}
            >
              That's why we built{" "}
              <strong
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                }}
              >
                voyayge
              </strong>{" "}
              to reverse this dynamic and have curated information flow to you,
              the traveler, by democratizing bespoke travel planning and making
              it accessible and affordable. <br></br> <br></br>At{" "}
              <strong
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                }}
              >
                voyayge
              </strong>{" "}
              we understand that your time is precious. So we are committed to
              building your customized travel plan to streamline the process and
              remove anxiety on your next trip. To that effect, we offer
              unlimited revisions to ensure you are satisfied with your plan.
              Because, We know you would rather spend your time doing the things
              you love!{" "}
              <i
                style={{
                  color: "#FeC600",
                }}
                className="fas fa-heart fa-lg"
              ></i>{" "}
              <br></br>
              {/* <br></br>{" "} */}
              <div style={{ maxWidth: "75px", margin: "15px auto" }}>
                <img src={wiggle} alt="" style={{ width: "100%" }} />
              </div>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "300",
                  lineHeight: "1.3",
                  // textTransform: "uppercase",
                }}
              >
                So go ahead,{" "}
                <span style={{}}>
                  give us a try and create lasting memories with{" "}
                </span>
                <strong
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                  }}
                >
                  voyayge
                </strong>{" "}
                <span>by your side.</span>
              </span>
            </p>

            {/* ❤️ */}
          </span>
        </div>
        <div className="column is-hidden-desktop is-hidden-tablet is-12-mobile">
          <div style={{ maxWidth: "300px", margin: "0 auto" }}>
            {/* <Image
              src="profile_vj.png"
              style={{
                width: "90%",
                borderRadius: "50%",
                border: "solid 3px #fec600",
                filter: "grayscale(90%)",
              }}
            ></Image> */}
            <img
              src={vjprofile}
              alt="card"
              style={{
                width: "100%",
                // borderRadius: "50%",
                // border: "solid 2px #fec600",
                padding: "20px",
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              VIJAY KOMPELLA
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              FOUNDER & CEO
            </p>
            <div
              style={{
                margin: "0 auto",
                maxWidth: "175px",
                textAlign: "center",
                display: "none",
              }}
            >
              <i
                className="fab fa-2x fa-linkedin"
                style={{
                  color: "#fec600",
                  // padding: "10px",
                  margin: "0 auto",
                  marginTop: "12px",
                  cursor: "pointer",
                  width: "100%",
                  //  textAlign:'center'
                }}
                onClick={() => {
                  customNavigateTo(
                    "https://www.linkedin.com/in/vijay-kompella-44953a3/"
                  )
                }}
              >
                {" "}
                <span
                  style={{
                    color: "#fec600",
                    // padding: "10px",
                    marginTop: "10px",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "400",
                    textAlign: "center",
                    letterSpacing: "1px",
                  }}
                >
                  /VKOMPELLA
                </span>
              </i>
            </div>
          </div>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-10-tablet is-12-mobile">
          {" "}
          <hr className="hr-branded"></hr>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        {/* <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-10-tablet">
          {" "}
          <p
            className=""
            style={{
              fontSize: "42px",
              fontWeight: "800",
              //textAlign: "center",
              marginBottom: "20px",
              color: "#fec600",
              lineHeight: "1.0",
            }}
          >
            <span
              style={{
                    color: "rgba(74,74,74,0.8)",
              }}
            >
              THE<br></br>TEAM
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
            </span>
          </p>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div> */}
        {/* <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-4-desktop is-5-tablet">

          <div style={{ maxWidth: "250px", margin: "0 auto" }}>
            <img
              src={vjprofile}
              alt="card"
              style={{
                width: "100%",
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              VIJAY KOMPELLA
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              FOUNDER & CEO
            </p>
            <div
              style={{
                margin: "0 auto",
                maxWidth: "175px",
                textAlign: "center",
              }}
            >
              <i
                className="fab fa-2x fa-linkedin"
                style={{
                  color: "#fec600",
                  // padding: "10px",
                  margin: "0 auto",
                  marginTop: "12px",
                  cursor: "pointer",
                  width: "100%",
                  //  textAlign:'center'
                }}
                onClick={() => {
                  customNavigateTo(
                    "https://www.linkedin.com/in/vijay-kompella-44953a3/"
                  )
                }}
              >
                {" "}
                <span
                  style={{
                    color: "#fec600",
                    // padding: "10px",
                    marginTop: "10px",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "400",
                    textAlign: "center",
                    letterSpacing: "1px",
                  }}
                >
                  /VKOMPELLA
                </span>
              </i>
            </div>
          </div>
        </div> */}
        {/* <div className="column is-4-desktop is-5-tablet">
          <div style={{ maxWidth: "250px", margin: "0 auto" }}>

            <img
              src={vjprofile}
              alt="card"
              style={{
                width: "100%",
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              TAMMY TAW
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              COO
            </p>
            <div
              style={{
                margin: "0 auto",
                maxWidth: "175px",
                textAlign: "center",
              }}
            >
              <i
                className="fab fa-2x fa-linkedin"
                style={{
                  color: "#fec600",
                  // padding: "10px",
                  margin: "0 auto",
                  marginTop: "12px",
                  cursor: "pointer",
                  width: "100%",
                  //  textAlign:'center'
                }}
                onClick={() => {
                  customNavigateTo(
                    "https://www.linkedin.com/in/vijay-kompella-44953a3/"
                  )
                }}
              >
                {" "}
                <span
                  style={{
                    color: "#fec600",
                    // padding: "10px",
                    marginTop: "10px",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "400",
                    textAlign: "center",
                    letterSpacing: "1px",
                  }}
                >
                  /TAMMYTAW
                </span>
              </i>
            </div>
          </div>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div> */}
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-10-tablet is-hidden-mobile">
          {" "}
          {/* <hr className="hr-branded"></hr> */}
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-10-tablet" id="commitments">
          {" "}
          <p
            className=""
            style={{
              fontSize: "42px",
              fontWeight: "800",
              //textAlign: "center",
              // marginBottom: "20px",
              color: "#fec600",
              lineHeight: "1.0",
            }}
          >
            <span
              style={{
                color: "rgba(74,74,74,0.8)",
              }}
            >
              OUR<br></br>COMMITMENTS
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
            </span>
          </p>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div
          className="column is-4-desktop is-5-tablet is-12-mobile"
          style={{ textAlign: "center" }}
        >
          {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>Traveler</p> */}
          {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>1</p> */}
          {/* <div style={{ maxWidth: "130px", margin: "0 auto" }}>
                  <img src={one} alt="" />
                </div> */}
          <div
            style={{
              width: "100px",
              height: "111px",
              margin: "0 auto",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            <LazyLoadImage
              src={climate}
              width="100px"
              height="111px"
              alt="build your profile"
              effect="blur"
            />
          </div>
          <p
            style={{
              fontSize: "22px",
              fontWeight: "800",
              textTransform: "uppercase",
              letterSpacing: "0.5px",
              marginBottom: "7px",
            }}
          >
            1% for carbon removal
          </p>{" "}
          <p
            style={{
              fontSize: "16px",
              fontWeight: "400",
              marginBottom: "10px",
              lineHeight: "1.8",
            }}
          >
            {" "}
            We realized the impact tourism has on global emissions. So, we have
            partnered with{" "}
            <span
              onClick={() => {
                window.open("https://stripe.com/climate", "_blank")
              }}
              style={{
                fontWeight: "600",
                cursor: "pointer",
                borderBottom: "2px solid #fec600",
                padding: "1px",
              }}
            >
              Stripe Climate
            </span>{" "}
            to contribute 1% of our revenue to fund carbon removal. These carbon
            removal efforts are geared towards long-term impact.
          </p>
        </div>
        <div
          className="column is-4-desktop is-5-tablet is-12-mobile"
          style={{ textAlign: "center" }}
        >
          <div
            style={{
              width: "100px",
              height: "111px",
              margin: "0 auto",
              marginTop: "50px",
              marginBottom: "20px",
            }}
          >
            <LazyLoadImage
              src={tree}
              width="100px"
              height="111px"
              alt="build your profile"
              effect="blur"
            />
          </div>
          <p
            style={{
              fontSize: "22px",
              fontWeight: "800",
              textTransform: "uppercase",
              letterSpacing: "0.5px",
              marginBottom: "7px",
            }}
          >
            PLAN A TRIP, PLANT A TREE
          </p>{" "}
          <p
            style={{
              fontSize: "16px",
              fontWeight: "400",
              marginBottom: "10px",
              lineHeight: "1.8",
            }}
          >
            {" "}
            Trees help clean the air we breathe, filter the water we drink, and
            provide habitat to over 80% of the world's terrestrial biodiversity.
            We have partnered up with{" "}
            <span
              onClick={() => {
                window.open("https://onetreeplanted.org/", "_blank")
              }}
              style={{
                fontWeight: "600",
                cursor: "pointer",
                borderBottom: "2px solid #fec600",
                padding: "1px",
              }}
            >
              ONETREEPLANTED
            </span>{" "}
            to plant trees around the world. With every trip taken, we'll
            contribute towards planting a tree.
          </p>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-10-tablet is-12-mobile">
          {" "}
          <hr className="hr-branded"></hr>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-10-tablet">
          {" "}
          <p
            className=""
            style={{
              fontSize: "42px",
              fontWeight: "800",
              //textAlign: "center",
              // marginBottom: "20px",
              color: "#fec600",
              lineHeight: "1.0",
            }}
          >
            <span
              style={{
                color: "rgba(74,74,74,0.8)",
              }}
            >
              OUR<br></br>ADVISORS
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
            </span>
          </p>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-10-tablet">
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-start",
            }}
          >
            <ProfileCards
              imageSrc="lynn"
              name="Lynn Mulvihill"
              title="Global Head of Marketing"
              company="Amazon Fashion"
            />
            <ProfileCards
              imageSrc="rosalie"
              name="Rosalie Villapando"
              title="Head of Planning & Operations Finance - AppleTV+"
              company="Apple"
            />
            <ProfileCards
              imageSrc="kiran"
              name="Kiran Brahmandam"
              title="Founder & CEO"
              company="Gaming Analytics Inc"
            />
            <ProfileCards
              imageSrc="drew"
              name="Andrew Marshall"
              title="Sr. Manager - Finance & Planning"
              company="Penske Media Corp."
            />
            <ProfileCards
              imageSrc="tammy"
              name="Tammy Taw"
              title="Product Growth Consultant - Google Play Partnerships"
              company="Google"
            />
          </div>

          {/* <div style={{ maxWidth: "250px", margin: "0 auto" }}>
            <img
              src="https://media-exp1.licdn.com/dms/image/C5603AQGXRU8H3yjOow/profile-displayphoto-shrink_800_800/0/1597499623289?e=1618444800&v=beta&t=VXZbWMdoCfW-_4G_-FBMfLKDqgeoRXbPRq_uLP0x3hs"
              alt="card"
              style={{
                width: "90%",
                borderRadius: "50%",
                border: "solid 3px #fec600",
                filter: "grayscale(100%)",
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              Lynn Mulvihill
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Head of Partnership Marketing and Promotions
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Disney Parks
            </p>
          </div> */}
        </div>
        {/* <div className="column is-2-desktop is-4-tablet">
          <div style={{ maxWidth: "250px", margin: "0 auto" }}>
            <img
              src="https://media-exp1.licdn.com/dms/image/C5603AQFZN8NyHQzsCQ/profile-displayphoto-shrink_400_400/0/1588654561310?e=1618444800&v=beta&t=Y3SnQQz6qHLD9rbY0i2vZYZakWVcjJgIdrYre_2JB2Q"
              alt="card"
              style={{
                width: "90%",
                borderRadius: "50%",
                border: "solid 3px #fec600",
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              Rosalie Villapando
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              SVP Strategic Finance & Operations
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              A+E Networks
            </p>
          </div>
        </div> */}
        {/* <div className="column is-2-desktop is-4-tablet">
          <div style={{ maxWidth: "250px", margin: "0 auto" }}>
            <img
              src="https://media-exp1.licdn.com/dms/image/C5603AQGqGo8fKRJhgg/profile-displayphoto-shrink_400_400/0/1517672455410?e=1618444800&v=beta&t=PAe06bkjpbIB4arrZOoB0fK4CxT7GPgU3vD_R1rSzcg"
              alt="card"
              style={{
                width: "90%",
                borderRadius: "50%",
                border: "solid 2px #fec600",
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              Kiran Brahmandam
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              FOUNDER & CEO
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Gaming Analytics Inc
            </p>
          </div>
        </div> */}
        {/* <div className="column is-2-desktop is-4-tablet">
          <div style={{ maxWidth: "250px", margin: "0 auto" }}>
            <img
              src="https://media-exp1.licdn.com/dms/image/C5603AQHk7uNgXCBRKw/profile-displayphoto-shrink_400_400/0/1521572324831?e=1618444800&v=beta&t=vHg1N72Ov719WWQRytt76Q8fyGjydfG-zU5fbEZG_sU"
              alt="card"
              style={{
                width: "90%",
                borderRadius: "50%",
                border: "solid 2px #fec600",
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              Andrew Marshall
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Director - International Sales Planning and Analytics
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Walt Disney Studios
            </p>
          </div>
        </div> */}
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>{" "}
        <div className="column is-4-desktop is-5-tablet is-hidden-mobile">
          {" "}
        </div>
        <div className="column is-4-desktop is-5-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-4-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-4-desktop is-10-tablet">
          {" "}
          <hr className="hr-branded"></hr>
        </div>
        <div className="column is-4-desktop is-1-tablet is-hidden-mobile"></div>
        {/* <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-1-tablet">
          {" "}
          <p
            className=""
            style={{
              fontSize: "42px",
              fontWeight: "800",
              //textAlign: "center",
              marginBottom: "20px",
              color: "#fec600",
              lineHeight: "1.0",
            }}
          >
            <span
              style={{
                    color: "rgba(74,74,74,0.8)",
              }}
            >
              OUR<br></br>VALUES
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              <i className="fas fa-heart" style={{ fontSize: "14px" }}></i>
            </span>
          </p>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-4-desktop is-5-tablet is-hidden-mobile">
          <p
            style={{
              fontSize: "22px",
              fontWeight: "900",
              textAlign: "left",
              color: "#fec600",
              marginBottom: "35px",
            }}
          >
            DREAM BIG FAIL FAST
          </p>
          <div className="testimonial-quote group">
            <div className="quote-container">
              <blockquote className="">
                <p>
                  There is only one thing that makes a dream impossible to
                  achieve: the fear of failure.
                </p>
              </blockquote>
            </div>
          </div>

          <p
            style={{
              fontSize: "20px",
              fontWeight: "400",
              textAlign: "left",
              paddingLeft: "55px",
            }}
          >
            ― Paulo Coelho, The Alchemist
          </p>
        </div>
        <div className="column is-4-desktop is-5-tablet is-hidden-mobile">
          <p
            style={{
              fontSize: "22px",
              fontWeight: "900",
              textAlign: "left",
              color: "#fec600",
              marginBottom: "35px",
            }}
          >
            DIVERSITY INCLUSION BELONGING
          </p>
          <div className="testimonial-quote group">
            <div className="quote-container">
              <blockquote className="">
                <p>
                  DIVERSITY is having a seat the table. INCLUSION is having a
                  voice and BELONGING is having that voice be heard.
                </p>
              </blockquote>
            </div>
          </div>

          <p
            style={{
              fontSize: "20px",
              fontWeight: "400",
              textAlign: "Left",
              paddingLeft: "55px",
            }}
          >
            ― Liz Fosslien
          </p>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div> */}
        <div className="column is-0-desktop is-0-tablet  is-hidden-mobile"></div>
        <div className="column is-12-desktop is-12-tablet  is-hidden-mobile">
          <section className="hero is-small" style={{}}>
            <div className="hero-body">
              <div className="container">
                <img src={herofun} alt="" />
              </div>
            </div>
          </section>
          {/* <div style={{ maxWidth: "600px", margin: "0px auto" }} className="">
            <Image
              src="gosee.png"
              style={{
                width: "50%",
              }}
            ></Image>
          </div> */}
        </div>
        <div className="column is-hidden-desktop is-hidden-tablet  is-12-mobile">
          <img src={herofun} alt="" />

          {/* <div style={{ maxWidth: "600px", margin: "0px auto" }} className="">
            <Image
              src="gosee.png"
              style={{
                width: "50%",
              }}
            ></Image>
          </div> */}
        </div>
        <div className="column is-0-desktop is-0-tablet  is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet  is-hidden-mobile"></div>
        <div className="column is-6-desktop is-1-tablet  is-hidden-mobile"></div>
        <div
          className="column is-2-desktop is-10-tablet"
          style={{ textAlign: "right" }}
        >
          <p
            className="link"
            style={{
              fontSize: "24px",
              fontWeight: "800",
              textAlign: "right",
              marginBottom: "100px",
              color: "#fec600",
              lineHeight: "1.0",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/contact")
            }}
          >
            <span
              style={{
                color: "rgba(74,74,74,0.8)",
              }}
            >
              GET<br></br>IN TOUCH
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
            </span>
          </p>
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
      </div>
    </div>
  )
}

export default About
