import React, { Fragment } from "react"
import Layout from "../components/layout"
import About from "../components/About/About"
import SEO from "../components/seo"

const AboutUsPage = () => {
  return (
    <Fragment>
      {" "}
      <About />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO
      pageTitle="About Us"
      description="About Us - Why we do what we do, our commitments and the team behind voyayge"
    />
    <AboutUsPage />
  </Layout>
)
